import React from 'react';

export const AddJournal = () => (
  <div>
    <form name="add-journal" data-netlify={true} data-netlify-honeypot="bot-field" hidden>
      <input type="hidden" name="form-name" value="add-journal" />
      <input type="hidden" name="bot-field" />
      <input type="text" name="pmid" />
      <textarea name="notes"></textarea>
    </form>
  </div>
);
export const Test = () => (
  <div>
    <form name="test" data-netlify={true} data-netlify-honeypot="bot-field" hidden>
      <input type="hidden" name="form-name" value="test" />
      <input type="hidden" name="bot-field" />
      <input type="text" name="pmid" />
      <textarea name="notes"></textarea>
    </form>
  </div>
);
export const Third = () => (
  <div>
    <form name="third" data-netlify={true} data-netlify-honeypot="bot-field" hidden>
      <input type="hidden" name="form-name" value="third" />
      <input type="hidden" name="bot-field" />
      <input type="text" name="one" />
      <textarea name="two"></textarea>
    </form>
  </div>
);

// const Contact = () => (
//   <div>
//     <form name="contact" data-netlify={true} data-netlify-honeypot="bot-field" hidden>
//       <input type="hidden" name="form-name" value="contact" />
//       <input type="hidden" name="bot-field" />
//       <input type="text" name="name" />
//       <input type="email" name="email" />
//       <textarea name="message"></textarea>
//     </form>
//   </div>
// );
export default AddJournal;
